@tailwind base;
@tailwind components;
@tailwind utilities;



.btn {
    @apply rounded;
}
.btn-secondary {
    @apply border text-blue px-7 py-3 border-solid border-blue font-semibold;
}


@font-face {
    font-family: 'dnsv-icon-font';
    src: url('../public/icons/dnsv/dnsv-icon-font.eot?11823215');
    src: url('../public/icons/dnsv/dnsv-icon-font.eot?11823215#iefix') format('embedded-opentype'),
    url('../public/icons/dnsv/dnsv-icon-font.woff2?11823215') format('woff2'),
    url('../public/icons/dnsv/dnsv-icon-font.woff?11823215') format('woff'),
    url('../public/icons/dnsv/dnsv-icon-font.ttf?11823215') format('truetype'),
    url('../public/icons/dnsv/dnsv-icon-font.svg?11823215#dnsv-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'dnsv-icon-font';
    src: url('../font/dnsv-icon-font.svg?11823215#dnsv-icon-font') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "dnsv-icon-font";
    font-style: normal;
    font-weight: normal;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-menu:before { content: '\e800'; } /* '' */
.icon-plus:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-mark:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-metro:before { content: '\e805'; } /* '' */
.icon-left-open:before { content: '\e806'; } /* '' */
.icon-right-open:before { content: '\e807'; } /* '' */
.icon-instagram:before { content: '\e808'; } /* '' */
.icon-male:before { content: '\e809'; } /* '' */
.icon-facebook:before { content: '\e80a'; } /* '' */
.icon-vk:before { content: '\e80b'; } /* '' */
.icon-female:before { content: '\e80c'; } /* '' */
.icon-position:before { content: '\e80d'; } /* '' */
.icon-viber:before { content: '\e80e'; } /* '' */
.icon-mail:before { content: '\e80f'; } /* '' */
.icon-up-circled2:before { content: '\e810'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap');

.header__nav > * {
    margin-left: 15px;
    margin-right: 15px;
}

.parallax__image {
    height: 837px;
}
.swiper-button-next:after, .swiper-button-prev:after {
    display: none;
}
.swiper-button-next, .swiper-button-prev {
    min-width: 44px;
}

body {
    font-size: 14px;
    background-color: rgba(231, 192, 255, 0.2);
}

select {
    background-image: url(../public/images/chevron-down.svg);
    background-position: right 8px center;
    background-repeat: no-repeat;
}

input[type="radio"] + label span {
    transition: background .2s,
    transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
    transform: scale(1.2);
}


input[type="radio"]:checked.male + label span {
    background-color: #08185F;
}
input[type="radio"]:checked.female + label span {
    background-color: #FF3D4F;
}

input[type="radio"]:checked + label span {
    box-shadow: 0px 0px 0px 2px white inset;
}









